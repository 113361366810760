import * as GETTER from './getter-types'

export default {
  [GETTER.GET_FILTER]: (state) => {
    const filters = {}
    Object.keys(state.facets).map((key) => {
      const facet = state.facets[key]
      if (facet.filter !== 'megatrend' && facet.filter !== 'region') {
        if (typeof filters[facet.filter] === 'undefined') {
          filters[facet.filter] = []
        }
        filters[facet.filter].push(facet.id)
      }
    })

    return filters
  },

  [GETTER.GET_TAG_IDS]: (state) => {
    const tags = []

    Object.keys(state.facets).map(key => {
      tags.push(state.facets[key].id)
    })

    return tags
  },

  [GETTER.GET_TAGS]: (state) => {
    const tags = []

    Object.keys(state.facets).map(key => {
      tags.push(state.facets[key])
    })

    return tags
  },

  [GETTER.GET_QUERY]: (state) => {
    return state.query || null
  },

  [GETTER.GET_QUERY_STRING]: (state) => {
    const value = {}
    if (state.query) {
      value.q = state.query
    }
    const f = Object.keys(state.facets).join(',')
    if (f) {
      value.f = f
    }

    return value
  },

  [GETTER.IS_FACET_ENTRY_CHECKED]: (state) => (payload) => {
    return typeof state.facets[payload.id] !== 'undefined'
  },

  [GETTER.IS_FACET_PARENT_CHECKED]: (state) => (payload) => {
    let cnt = 0

    for (const key of Object.keys(payload.children)) {
      cnt += key in state.facets ? 1 : 0
    }

    if (cnt === 0) {
      return false
    }

    if (cnt < Object.keys(payload.children).length) {
      return null
    }

    return true
  },

  [GETTER.GET_COMPANY_TRENDS_ONLY]: (state) => state.companyTrendsOnly.microtrends,
  [GETTER.GET_TRENDONE_TRENDS_ONLY]: (state) => state.trendoneTrendsOnly.microtrends,
  [GETTER.GET_TRENDS_WITH_USE_CASES_ONLY]: (state) => state.trendsWithUseCasesOnly,

  [GETTER.GET_SIDEBAR]: (state) => state.sidebar,

  [GETTER.GET_DATE_RANGE]: (state) => state.dateRange
}
