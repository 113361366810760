export enum UPDATE_TYPE {
  INTRODUCED_MEGATREND = 'introducedMegatrend',
  REVISED_MEGATREND = 'revisedMegatrend',
  INTRODUCED = 'introduced',
  REVISED = 'revised',
  REPLACED = 'replaced',
  MERGED = 'merged'
}

export interface IntroducedTrend {
  title: string
  image: string
  updateInfo: string
  type: UPDATE_TYPE
}

export interface RevisedTrend {
  title: string
  image: string
  updateInfo: string
  type: UPDATE_TYPE
}

export interface Successor {
  title: string
  image: string
  updateInfo: string
}

export interface Predecessor {
  title: string
  image: string
}

export interface ReplacedTrend {
  successor: Successor
  predecessor: Predecessor
  type: UPDATE_TYPE
}

export interface MergedTrend {
  successor: Successor
  predecessor: Predecessor
  type: UPDATE_TYPE
}

export type UpdateTrend = IntroducedTrend | RevisedTrend | ReplacedTrend | MergedTrend
