
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UPDATE_TYPE, UpdateTrend } from '@/model/universe-update/types'
import LoadingCircle from '@/components/loading-circle/loading-circle.vue'

@Component({
  components: {
    LoadingCircle,
    [UPDATE_TYPE.INTRODUCED_MEGATREND]: () => import(/* webpackChunkName: "[request]" */ './introduced-trend.vue'),
    [UPDATE_TYPE.REVISED_MEGATREND]: () => import(/* webpackChunkName: "[request]" */ './revised-trend.vue'),
    [UPDATE_TYPE.INTRODUCED]: () => import(/* webpackChunkName: "[request]" */ './introduced-trend.vue'),
    [UPDATE_TYPE.MERGED]: () => import(/* webpackChunkName: "[request]" */ './merged-trend.vue'),
    [UPDATE_TYPE.REPLACED]: () => import(/* webpackChunkName: "[request]" */ './replaced-trend.vue'),
    [UPDATE_TYPE.REVISED]: () => import(/* webpackChunkName: "[request]" */ './revised-trend.vue')
  }
})
export default class TrendUpdates extends Vue {
  @Prop({ type: Boolean, required: true }) admin!: Boolean
  @Prop({ type: Boolean, default: () => false }) loading!: Boolean
  @Prop({ type: Array }) trends!: Array<UpdateTrend>
  @Prop({ type: Number, required: false }) startingIndex!: number

  currentIndex: number = 0

  get currentTrend (): UpdateTrend | null {
    return this.trends[this.currentIndex] || null
  }

  get forwardButtonText (): string {
    if (this.currentIndex !== this.numberOfTrends - 1) {
      return this.$t('components.universeUpdate.trendUpdates.actions.forward').toString()
    }

    if (this.admin) {
      return this.$t('components.universeUpdate.trendUpdates.actions.adminBackend').toString()
    }

    return this.$t('components.universeUpdate.trendUpdates.actions.close').toString()
  }

  get numberOfTrends (): number {
    return this.trends.length
  }

  prev (): void {
    if (this.currentIndex > 0) {
      this.currentIndex--
    }
  }

  get disabled (): boolean {
    return this.currentIndex === 0
  }

  next (): void {
    if (this.currentIndex < this.numberOfTrends - 1) {
      this.currentIndex++
    } else {
      if (this.admin) {
        this.$emit('go-to-admin')
      }
      this.onClose()
    }
  }

  onClose (): void {
    this.$emit('close')
  }

  mounted (): void {
    this.currentIndex = this.startingIndex || 0
  }
}
