export const GET_FILTER = 'getFilter'
export const GET_TAG_IDS = 'getTagIds'
export const GET_TAGS = 'getTags'
export const GET_QUERY = 'getQuery'
export const GET_QUERY_STRING = 'getQueryString'
export const IS_FACET_ENTRY_CHECKED = 'isFacetEntryChecked'
export const IS_FACET_PARENT_CHECKED = 'isFacetParentChecked'
export const GET_COMPANY_TRENDS_ONLY = 'GET_COMPANY_TRENDS_ONLY'
export const GET_TRENDONE_TRENDS_ONLY = 'GET_TRENDONE_TRENDS_ONLY'
export const GET_TRENDS_WITH_USE_CASES_ONLY = 'GET_TRENDS_WITH_USE_CASES_ONLY'
export const GET_SIDEBAR = 'GET_SIDEBAR'
export const GET_DATE_RANGE = 'GET_DATE_RANGE'
