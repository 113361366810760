export const ADD_ENTRY = 'ADD_ENTRY'
export const ADD_PARENT = 'ADD_PARENT'
export const REMOVE_ENTRY = 'REMOVE_ENTRY'
export const REMOVE_PARENT = 'REMOVE_PARENT'
export const RESET = 'RESET'
export const RESET_QUERY = 'RESET_QUERY'
export const SET_QUERY = 'SET_QUERY'
export const INIT = 'INIT'
export const SET_COMPANY_TRENDS_ONLY = 'SET_COMPANY_TRENDS_ONLY'
export const SET_TRENDONE_TRENDS_ONLY = 'SET_TRENDONE_TRENDS_ONLY'
export const SET_TRENDS_WITH_USE_CASES_ONLY = 'SET_TRENDS_WITH_USE_CASES_ONLY'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const RESET_DATE_RANGE = 'RESET_DATE_RANGE'
